<template>
  <div>
    <button class="password-btn" @click="toggleModal">Passwort vergessen?</button>
    <div class="popup-background" v-if="showModal == 1">

     <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="popup">
      <div class="popup-dialog">
        <div class="popup-content ">
          <div class="popup-header d-flex flex-row justify-content-between ">
            <h5 class="popup-title" id="exampleModalLabel">Passwort vergessen</h5>
          </div>
          <div class="popup-body py-5 px-3">
            <div v-if="!sending && !sent">
                <small>Bitte geben Sie die Email-Adresse ein mit der Sie sich bei smarte-heizung.com registriert haben. Wir senden Ihnen dann einen Link zum zurücksetzen des Passwortes.</small>
                <input type="text" className="form-control mt-2" placeholder="E-Mail" aria-label="E-Mail" id="email"
                       v-model="email"
                       aria-describedby="basic-addon1">
            </div>
            <div v-if="sending" class="text-center">
              <Spinner />
            </div>
            <div v-if="sent" class="text-center">
              Bitte überprüfen Sie Ihre Emails (gegebenenfalls auch den Spam-Filter) und folgen Sie den Anweisungen.
            </div>
          </div>


          </div>
          <div class="popup-footer mt-5">
            <div v-if="!sending && !sent">
              <button type="button" @click="resetPassword">Passwort zurücksetzen</button>
              <button class="btn btn-link ms-1" @click="toggleModal">Abbrechen</button>
            </div>
            <div v-if="sent">
              <button type="button" @click="toggleModal">Schliessen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
     </div>
    </div>
<!--  </div>-->
<!--  </div>-->
</template>

<script>
import Spinner from "@/components/SingleComponents/Spinner.vue";
import VueJwtDecode from 'vue-jwt-decode';
export default {
  name: "UserModal",
  components: {Spinner},
  props: ['userId'],
  data() {
    return {
      showModal: false,
      email: '',
      sent: false,
      sending: false,
      loading: false,
    }
  },
  watch: {
    user(newRole, oldRole) {
      if (newRole.userRole == 3) {
        // //console.log("Neue Rolle!!!")
        this.noRooms = true;

      }
    }
  },
  methods: {
    test() {
      // //console.log("Cliock", this.rooms)
    },
    toggleModal() {
      this.showModal = !this.showModal
      },
    resetPassword() {
        this.sending = true;
      this.loading = true
      let data = {'email' : this.email}
      console.log("Data", data)
      fetch('https://api.smarte-heizung.com/misc/user/reset', {
        method: "post",
        headers: {
          "Accept": "application/json",
          "Content-type": "application/json",
          // "x-access-token": token,
          // "X-RapidAPI-Host": 'roomss-by-api-ninjas.p.rapidapi.com',
        },
        body: JSON.stringify(data),
      })
          .then(response => {
            if (response.status == 200) {
             this.sending = false
            this.sent= true

            }
            console.log("Response", response)

               })
          .catch((err) => {
            //console.error(err);
          });
    },

  },
  computed: {

  },
  mounted() {

  }
}
</script>

<style scoped>
.fade {
  opacity: 1;
}
.popup-background {
  position:absolute;
  left: 0;
  top: 0;
  z-index:1000;
  width:100%;
  height:100%;
  background:RGBA(0,0,0,.7);
  position: fixed;
  overflow-y: scroll;
}


.popup {
  margin-top: 10vh;
  width: 40%;
  /*min-height: 80vh;*/
  background: white;
  padding:2em;
  border-radius:5px;
  box-shadow:10px 10px 10px black;
  margin-left:auto;
  margin-right: auto;
}

.popup-dialog {
  min-height: 100%;

}

.user-popup {
  background: var(--color-primary);
}

.password-btn {
  border: 0;
  color: var(--color-primary);
}

.password-btn:hover {
  background: var(--color-primary);
  color:white;
}


button span {
  color:white;

}
</style>
