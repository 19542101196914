<template>
  <div className="back">
    <div className="container px-4 position-relative d-flex flex-column">
      <!--    <div class="position-absolute top-0 start-0 ms-3 mt-3">-->
      <!--      <a href="" class="h-100 w-100 d-flex"><img src="bilder/placeholder.jpg" alt="" class="profilbild"></a>-->
      <!--    </div>-->
      <div className="logo-white"><a href=""><img src="/img/logo-white.svg" alt="Logo"></a></div>
      <div className="logo-black"><a href=""><img src="/img/logo-black.svg" alt="Logo"></a></div>
      <div v-if="wrong" className="m-3 text-bg-warning p-3">
        Ihr Passwort oder Login ist falsch. Bitte versuchen Sie es noch einmal.
      </div>
      <div v-if="new" className="m-3 text-bg-warning p-3">
        Diese Email ist nicht bekannt. Bitte
        <router-link to="/register">registrieren</router-link>
        Sie sich!
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center h-100 ">

        <form className="col-8 mx-auto text-center startformular">


          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="E-Mail" aria-label="E-Mail" id="username"
                   v-model="input.username"
                   aria-describedby="basic-addon1">
          </div>

          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Passwort" aria-label="Passwort" id="password"
                   v-model="input.password"
                   aria-describedby="basic-addon2">

          </div>
          <div className="mb-3">
            <button type="submit" v-on:click.prevent="login()">
              Anmelden
            </button>
          </div>
        </form>
        <div className="w-50 border-top border-2 mb-3"></div>
        <p className="text-white">Noch keinen Account? <router-link to="/register">Registrieren</router-link></p>
        <PassordForgottenModal />
      </div>
    </div>
    <h3>{{ this.output }}</h3>
  </div>
</template>

<script>
import {useStore} from 'vuex'
import {SET_AUTHENTICATION, SET_USERNAME} from "../store/storeconstants";
import md5 from 'md5'
import VueJwtDecode from 'vue-jwt-decode';
import PassordForgottenModal from "@/PassordForgottenModal.vue";

export default {
  components: { PassordForgottenModal},
  name: 'LoginView',
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      output: "",
      wrong: false,
      new: false,
      userAnlagen: '',
      userRooms: '',

    }
  },
  mounted() {
    // //console.log("env", process.env.VUE_APP_SOMEKEY)
  },
  // mounted() {
  //   import('../assets/data.json').then(data => {
  //     this.users = data.default
  //   })
  // },
  methods: {
    login() {

      if (this.input.username != "" && this.input.password != "") {
        console.log("Logging in")
        const user = {'email': this.input.username, 'password': this.input.password}
        this.axios.put("https://api.smarte-heizung.com/users/login", user)
            .then(response => {
              console.log("response", response.data)
              if (response.data.message === 'Wrong password or email') {
                console.log("KENN ICH NICHT!")
                this.new = false;
                this.wrong = true;
              } else if (response.data.message === 'User doesn\'t exist. Please register') {
                console.log("Please register")
                this.wrong = false;
                this.new = true;
              } else {
                this.wrong = false;
                this.new = false;
                $cookies.set("token", response.data.message.token)
                $cookies.set("id", response.data.message.id)
                $cookies.set("role", response.data.message.userRole)
                const decoded = VueJwtDecode.decode(response.data.message.token)
                //console.log("Decoded", decoded)
                // //console.log("UserRole", response.data.message.userRole)
                // if (this.userAnlagen) {
                switch (decoded.role) {
                  case 1:
                    //console.log("ADMIN!")
                    this.$router.push('/anlagen');
                    break;
                  case 2:
                    if (decoded.anlagen.length > 1) {
                      this.$router.push('/anlagen');
                    } else {
                      this.$router.push('/anlagen/details/' + decoded.anlagen[0].anlagenId);
                    }
                    // this.getUserAnlagen(response.data.message.id, response.data.message.token)
                    break;
                  case 3:
                    //console.log("Case:3")
                      if (!decoded.anlagen)
                        {
                          this.$router.push('/anlagen/details/' + decoded.anlagen[0].anlagenId);
                        } else
                        {
                          this.$router.push('/keine-rechte');
                        }
                    break;
                  default:
                    this.$router.push('/keine-rechte');
                }
              }

              // }
            })
      }

    },


  },


}
</script>
<style scoped>
.container {
  align-items: center;
}
.back {
  width: 99vw;
  background: var(--color-second);
  color: var(--color-primary);
}

button {
  background: var(--color-primary) !important;
}

button:hover {
  color: var(--color-primary) !important;
  background: transparent !important;
}


a {
  color: var(--color-primary) !important;
}

a:hover {
  color: white !important;
}

</style>
