import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useStore } from 'vuex'
import {IS_USER_AUTHENTICATED, GET_USERNAME } from "../store/storeconstants";
import loginView from "../components/Login.vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueJwtDecode from 'vue-jwt-decode';

const routes = [
  {
    path: '/',
    name: 'root',
    component: HomeView,
    props: true,
  },
  {
    path: '/anlagen',
    name: 'anlagen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Anlagen/Anlagen.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Register.vue')
  },
  {
    path: '/einladung',
    name: 'einladung',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/User/Einladung.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ResetPassword.vue')
  },
  {
    path: '/anlage/raum/details/:id/',
    name: 'raumdetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Rooms/RoomDetails.vue'),
    props: true,
  },
  {
    path: '/anlagen/details/:id/',
    name: 'anlagendetails',
    component: () => import(/* webpackChunkName: "about" */ '../components/Anlagen/AnlagenDetails.vue'),
    props: true,
  },
  {
    path: '/anlagen/details/:id/edit',
    name: 'EditAnlage',
    component: () => import(/* webpackChunkName: "about" */ '../components/Anlagen/EditAnlage.vue'),
    props: true,
  },
  {
    path: '/anlagen/neu',
    name: 'createanlage',
    component: () => import(/* webpackChunkName: "about" */ '../components/Anlagen/CreateAnlage.vue'),
    // props: true,
  },
  {
    path: '/anlagen/details/:id/create-room',
    name: 'createRoom',
    component: () => import(/* webpackChunkName: "about" */ '../components/Rooms/CreateRoom.vue'),
    props: true,
  },
  {
    path: '/einstellungen/:userid',
    name: 'Einstellungen',
    component: () => import(/* webpackChunkName: "about" */ '../components/User/Einstellungen.vue'),
    props: true,
  },
  {
    path: '/benutzer-verwaltung',
    name: 'Benutzerverwaltung',
    component: () => import(/* webpackChunkName: "about" */ '../components/User/Verwaltung.vue'),
    props: true,
  },
  {
    path: '/keine-rechte',
    name: 'KeineRechte',
    component: () => import(/* webpackChunkName: "about" */ '../components/User/KeineRechte.vue'),
    props: true,
  },
  {
    path: '/loggedin',
    name: 'loggedin',
    component: () => import(/* webpackChunkName: "about" */ '../components/User/LoggedIn.vue'),
    props: true,
  },
  {
    path: '/heizprofile',
    name: 'Heizprofile',
    component: () => import(/* webpackChunkName: "about" */ '../components/Heizprofile/Heizprofiluebersicht.vue'),
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const token = $cookies.get("token");
  const id = $cookies.get("id");
  const user = {"id": id, "token": token}

  if (to.name != 'register' && to.name != 'login' && to.name != 'root' && to.name != 'einladung' && to.name != 'reset') {
    if (token) {
      const decoded = VueJwtDecode.decode(token)
      const userRole = decoded.role
      axios.post("https://api.smarte-heizung.com/users/auth", user)
          .then(response => {
            if (response.message != 200) {
              // redirect the user to the login page
              return {name: 'root'}
            }
          })

      if (userRole != 1) {
        switch (to.name) {
          case "Benutzerverwaltung":
            if (userRole >= 3) {
              return {name: 'KeineRechte'}
            } else {
              return
            }
            break;
          case "createanlage":
            return {name: 'KeineRechte'}
            break;
          case "anlagendetails":
            //console.log("id:", to.params.id);
            //console.log("decoded.anlagen:", decoded.anlagen);
            let foundAnlage = decoded.anlagen.find(anlage => anlage.anlagenId == to.params.id);
            //console.log("foundAnlage:", foundAnlage);

            if (foundAnlage == undefined || foundAnlage.length == 0) {
              return {name: 'KeineRechte'}
            }
            break;
          case "EditAnlage":
            //console.log("id:", to.params.id);
            //console.log("decoded.anlagen:", decoded.anlagen);
            let foundEditAnlage = decoded.anlagen.find(anlage => anlage.anlagenId == to.params.id);


            if (foundEditAnlage == undefined || foundEditAnlage.length == 0) {
              return {name: 'KeineRechte'}
            }
            break;
        }
      }

      if (userRole == 3) {
        if (to.name == 'createRoom') {
          return {name: 'KeineRechte'}
        }
      }
    } else {
      return {name: 'root'}
    }

  }
})


export default router
